import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { usePatientAuth } from "context/patient-auth";
import { useParams } from "react-router-dom";

type ReportHtml = string | null;
type ReportPdf = string | null;
type ReportData = Record<string, unknown> | null;

interface RiskReportContextType {
  error: boolean;
  isLoading: boolean;
  reportHtml: ReportHtml;
  reportPdf: ReportPdf;
  reportData: ReportData;
  refetchRiskReport: () => void;
}

export const RiskReportContext = createContext<
  RiskReportContextType | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

export function RiskReportProvider({ children }: Props) {
  const fetchedRef = useRef(false);
  const { logout } = usePatientAuth();
  const adminContent = useAdminContent();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [reportHtml, setReportHtml] = useState<ReportHtml>(null);
  const [reportPdf, setReportPdf] = useState<ReportPdf>(null);
  const [reportData, setReportData] = useState<ReportData>(null);

  const { reportId } = useParams<{ reportId: string }>();

  const fetchRiskReport = useCallback(async () => {
    const patientId = localStorage.getItem("ciq_id");
    const idToken = localStorage.getItem("id_token");

    if (!adminContent || adminContent.isLoading || !patientId || !idToken) {
      return;
    }

    setLoading(true);

    const id = reportId || "current"; // use "current" to get the most recent one, or a document id to fetch an old report.

    try {
      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/risk_reports/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          logout();
        } else {
          setError(true);
        }
        return;
      }

      const { report } = await response.json();

      setReportHtml(report.html);
      setReportPdf(report.pdf);
      setReportData({ ...report.data, date: report.date });
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [adminContent, logout, reportId]);

  useEffect(() => {
    if (adminContent && !adminContent.isLoading && !fetchedRef.current) {
      fetchedRef.current = true;
      fetchRiskReport();
    }
  }, [adminContent, fetchRiskReport]);

  const contextProps = useMemo(
    () => ({
      error,
      isLoading,
      reportHtml,
      reportPdf,
      reportData,
      refetchRiskReport: fetchRiskReport,
    }),
    [error, isLoading, reportData, reportHtml, reportPdf, fetchRiskReport]
  );

  return (
    <RiskReportContext.Provider value={contextProps}>
      {children}
    </RiskReportContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useRiskReport() {
  const context = useContext(RiskReportContext);

  if (!context) {
    throw new Error("useRiskReport must be used within a RiskReportProvider");
  }

  return context;
}
