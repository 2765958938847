import React from "react";
// @ts-ignore package has no types
import { Loading } from "components/loading";
import { useAdminContent, useFeatureFlags } from "context/admin-content";
import { PatientInterestProvider } from "context/patient-interest";
import { RiskReportProvider } from "context/risk-report";
import { RiskProfileProvider } from "context/risk-profile";
import { Completed } from "./completed";

import { PatientResultsDisplay } from "./patient-results-display";

export default function PatientResultsPage() {
  const adminData = useAdminContent();
  const featureFlags = useFeatureFlags();
  const surveyType = localStorage.getItem("surveyType");
  const params = new URLSearchParams(window.location.search);
  const fromRescreen = params.get("from") === "rescreen";

  if (!adminData || adminData.isLoading) {
    return <Loading />;
  }

  if (fromRescreen && adminData?.rescreen?.approval_enabled) {
    return <Completed />;
  }

  if (
    !fromRescreen &&
    featureFlags?.disablePatientApprovalForRemoteScreenings === false &&
    surveyType === "remote"
  ) {
    return <Completed />;
  }

  return (
    <PatientInterestProvider>
      <RiskProfileProvider>
        <PatientResultsDisplay />
      </RiskProfileProvider>
    </PatientInterestProvider>
  );
}
