import { Redirect } from "react-router-dom";
import { SurveyKind, SurveyProvider, useSurveyContext } from "context/survey";
import { ErrorType } from "env-vars";
import { RiskReportProvider } from "context/risk-report";
import { Loading } from "components/loading";
import { InitialSurvey } from "pages/patient-landing/initial-survey";
import { PatientLongSurvey } from "./patient-long-survey";
import { RescreenLanding } from "./rescreen-landing";

function LandingPage() {
  const { error, isLoading, surveyKind } = useSurveyContext();
  const patientId = localStorage.getItem("ciq_id") as string;

  if (isLoading) {
    return <Loading />;
  }

  if (error === ErrorType.AUTH) {
    localStorage.clear();

    return <Redirect to="/login/passwordless" />;
  }

  if (error === ErrorType.GENERAL) {
    return <div>Error loading survey</div>;
  }

  if (surveyKind === SurveyKind.INITIAL) {
    return <InitialSurvey />;
  }

  if (surveyKind === SurveyKind.RESCREEN) {
    return <RescreenLanding />;
  }

  if (surveyKind === SurveyKind.LONG) {
    return <PatientLongSurvey userId={patientId} />;
  }

  // TODO: Style error messages, though this is an edge case
  return <div>Error loading survey</div>;
}

export default function PatientLanding() {
  return (
    <SurveyProvider>
      <LandingPage />
    </SurveyProvider>
  );
}
